import $ from "jquery";
import "popper.js";
import "bootstrap";

$(function () {
  function getCookie(name) {
    var v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    return v ? v[2] : null;
  }

  function setCookie(name, value, days) {
    var d = new Date();
    d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
  }

  function deleteCookie(name) {
    setCookie(name, "", -1);
  }

  let sideMenuOpen = false;

  $('[data-role="side-menu-toggle"]').on("click", function (e) {
    e.preventDefault();
    if (sideMenuOpen == false) {
      $(".side-offer").animate({ right: 0 });
      sideMenuOpen = true;
    } else {
      $(".side-offer").animate({ right: -300 });
      sideMenuOpen = false;
    }
  });

  $('[data-role="pop-up-offer-dismiss"]').on("click", function () {
    $(".pop-up-offer").remove();
    setCookie("pop-up-offer", "closed", 1);
  });

  if (getCookie("pop-up-offer") === null) {
    $(window).scroll(function () {
      if ($(".pop-up-offer").length > 0) {
        let scrollTop = $(window).scrollTop();

        if (scrollTop > 200) {
          $(".pop-up-offer").removeClass("d-none");
        } else {
          $(".pop-up-offer").addClass("d-none");
        }
      }
    });
  }
});
